module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/vercel/workpath0/src/layouts/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"David Richter","short_name":"David Richter","start_url":"/","background_color":"#171717","theme_color":"#3b6a6f","display":"standalone","include_favicon":false,"icon":"src/icons/icon.png","cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"1c6248c0ea526dac8283d44b775e8763"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/arbeiten","/impressum","/datenschutz","/arbeiten/*",""]},
    }]
